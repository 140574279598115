import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  Filter,
  required,
  useRecordContext,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  ImageInput,
  ImageField,
  useNotify,
  ListButton,
  usePermissions,
  Toolbar,
  DeleteButton,
  minLength,
  SaveButton,
  BooleanField,
} from 'react-admin';

import { IMAGE_MAX_SIZE } from '../../helpers/constants';
import ToggleMenu from './toggle-menu';

const MenusTitle = () => {
  const record = useRecordContext();
  if (!record) return null;

  return <span>Menu {record ? `"${record.name}"` : ''}</span>;
};

const MenusFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar" source="q" alwaysOn />
  </Filter>
);

const CustomToolbar = ({ permissions, ...props }) => (
  <Toolbar
    {...props}
    className="botoesUpdate"
    style={{ justifyContent: 'space-between' }}
  >
    <div
      style={{
        width: '250px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <SaveButton alwaysEnable />
      <ListButton
        size="medium"
        variant="outlined"
        label="cancelar"
        icon={<CloseIcon />}
      />
    </div>
    {!permissions.isLoading &&
      (permissions.permissions.includes('SYS_ADMIN') ||
        permissions.permissions.includes('ROLE_MENUS_DELETE')) && (
        <DeleteButton size="medium" />
      )}
  </Toolbar>
);

export const Menus = (props) => (
  <List perPage={25} title="Menus do Site" filters={<MenusFilter />} {...props}>
    <Datagrid>
      <ToggleMenu label="Ativo" source="disabled" {...props} />
      <TextField label="Nome" source="name" />
      <ReferenceField label="Menu Pai" source="menu_id" reference="menus">
        <TextField source="name" />
      </ReferenceField>
      <TextField label="Link" source="html" />
      <TextField label="Ordem" source="order" />
      <EditButton />
    </Datagrid>
  </List>
);

const If = ({ condition, element }) => {
  const record = useRecordContext();
  console.log(record);
  if (!record) return null;
  return (record?.metadata?.[condition] ?? false) ? element : <></>;
};

export const MenusEdit = (props) => {
  const record = useRecordContext();
  const permissions = usePermissions();
  const notify = useNotify();
  const onError = (data) => {
    notify(data.message);
  };

  return (
    <Edit redirect="list" title={<MenusTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        <Box>
          <TextInput sx={{ mr: 2 }} label="Nome" validate={required()} source="name" />
          <If condition="hasEnglish"
            element={<TextInput sx={{ mr: 2 }} label="Nome Inglês" source="name_english" />}
          />
          <If condition="hasSpanish"
            element={<TextInput sx={{ mr: 2 }} label="Nome Espanhol" source="name_spanish" />}
          />
        </Box>
        <Box>
          <TextInput sx={{ mr: 2 }} label="Descrição" source="description" />
          <If condition="hasEnglish"
            element={<TextInput sx={{ mr: 2 }} label="Descrição Inglês" source="description_english" />}
          />
          <If condition="hasSpanish"
            element={<TextInput sx={{ mr: 2 }} label="Descrição Espanhol" source="description_spanish" />}
          />
        </Box>
        <TextInput label="Link" source="html" validate={required()} />
        <ReferenceInput
          label="Menu Pai"
          source="menu_id"
          reference="menus"
          allowEmpty
        >
          <SelectInput optionText="name" label="Menu" />
        </ReferenceInput>
        <TextInput
          label="Ordem (default: ultimo)"
          source="order"
          type="text"
          defaultValue=""
          parse={(value) => {
            // eslint-disable-next-line
            if (!isNaN(value)) return Number(value) < 1 ? '' : value;
            return '';
          }} />
        <ImageInput
          source="pictures"
          maxSize={IMAGE_MAX_SIZE}
          options={{
            onDropRejected: (res) => {
              if (res[0].errors[0].code === 'file-too-large') {
                notify('Arquivo excede 15MB', { type: 'warning' });
              }
            },
          }}
          label="Imagem (jpeg, png, jpg, gif, svg, webp, bmp)"
          accept="image/*"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <BooleanInput label="É link?" source="isLink" defaultValue={false} />
      </SimpleForm>
    </Edit>
  );
};

export const MenusCreate = (props) => {
  const permissions = usePermissions();
  const notify = useNotify();
  const onError = (data) => {
    notify(data.message);
  };

  return (
    <Create redirect="list" {...props} title="Novo Menu">
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        <Box>
          <TextInput sx={{ mr: 2 }} label="Nome" validate={required()} source="name" />
          <TextInput sx={{ mr: 2 }} label="Nome Inglês" source="name_english" />
          <TextInput sx={{ mr: 2 }} label="Nome Espanhol" source="name_spanish" />
        </Box>
        <Box>
          <TextInput sx={{ mr: 2 }} label="Descrição" source="description" />
          <TextInput sx={{ mr: 2 }} label="Descrição Inglês" source="description_english" />
          <TextInput sx={{ mr: 2 }} label="Descrição Espanhol" source="description_spanish" />
        </Box>
        <TextInput label="Link" source="html" validate={required()} />
        <ReferenceInput
          label="Menu Pai"
          source="menu_id"
          reference="menus"
          allowEmpty
        >
          <SelectInput optionText="name" label="Menu" />
        </ReferenceInput>
        <TextInput
          label="Ordem (default: ultimo)"
          source="order"
          type="text"
          defaultValue=""
          parse={(value) => {
            // eslint-disable-next-line
            if (!isNaN(value)) return Number(value) < 1 ? '' : value;
            return '';
          }} />
        <ImageInput
          source="pictures"
          maxSize={IMAGE_MAX_SIZE}
          options={{
            onDropRejected: (res) => {
              if (res[0].errors[0].code === 'file-too-large') {
                notify('Arquivo excede 15MB', { type: 'warning' });
              }
            },
          }}
          label="Imagem (jpeg, png, jpg, gif, svg, webp, bmp)"
          accept="image/*"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <BooleanInput label="É link?" source="isLink" defaultValue={false} />
      </SimpleForm>
    </Create>
  );
};

export default Menus;
