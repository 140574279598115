import './style.css';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import {
  ArrayInput,
  List,
  Datagrid,
  EditButton,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  ImageInput,
  ImageField,
  Filter,
  BulkDeleteButton,
  Toolbar,
  SaveButton,
  DeleteButton,
  required,
  TextField,
  BooleanInput,
  usePermissions,
  useRecordContext,
  useNotify,
  ListButton,
  SimpleFormIterator,
  FormDataConsumer,
  AutocompleteInput,
} from 'react-admin';
import { useCookies } from 'react-cookie';

import ImagePreview from '../../components/ImagePreviewWithShowFirst';
import { IMAGE_MAX_SIZE } from '../../helpers/constants';
import ToggleBanner from './toggleBanner';

const BannerInternoTitle = () => {
  const record = useRecordContext();
  if (!record) return null;

  return <span>Banner Interno da Página {record.page}</span>;
};

const BannerInternoFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar" source="q" alwaysOn />
  </Filter>
);

const PostBulkActionButtons = ({ permissions, ...props }) => (
  <>
    {!permissions.isLoading &&
      (permissions.permissions.includes('SYS_ADMIN') ||
        permissions.permissions.includes('ROLE_BANNER_INTERNO_DELETE')) && (
        <BulkDeleteButton {...props} />
      )}
  </>
);

export const BannerInterno = (props) => {
  const permissions = usePermissions();

  return (
    <List
      perPage={25}
      title="Banners Interno"
      filters={<BannerInternoFilter />}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
    >
      <Datagrid
        bulkActionButtons={<PostBulkActionButtons permissions={permissions} />}
      >
        <ToggleBanner label="Ativo" source="disabled" {...props} />
        <ImageField
          sortable={false}
          source="image"
          label="Imagem"
          title="Banner"
        />
        <TextField label="Página" source="page" />
        {!permissions.isLoading &&
          (permissions.permissions.includes('SYS_ADMIN') ||
            permissions.permissions.includes('ROLE_BANNER_INTERNO_UPDATE')) && (
            <EditButton />
          )}
      </Datagrid>
    </List>
  );
};

const CustomToolbar = ({ permissions, ...props }) => (
  <Toolbar
    {...props}
    className="botoesUpdate"
    style={{ justifyContent: 'space-between' }}
  >
    <div
      style={{
        width: '250px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <SaveButton alwaysEnable />
      <ListButton
        size="medium"
        variant="outlined"
        label="cancelar"
        icon={<CloseIcon />}
      />
    </div>
    {!permissions.isLoading &&
      (permissions.permissions.includes('SYS_ADMIN') ||
        permissions.permissions.includes('ROLE_BANNER_INTERNO_DELETE')) && (
        <DeleteButton size="medium" />
      )}
  </Toolbar>
);

export const BannerInternoEdit = (props) => {
  const permissions = usePermissions();
  const notify = useNotify();
  const onError = (data) => {
    notify(data.message);
  };

  return (
    <Edit redirect="list" title={<BannerInternoTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        <TextInput
          label="Página"
          fullWidth
          source="page"
          validate={required()}
        />
        <ArrayInput className="breadcrumbsInputArray" source="breadcrumbs" label="Breadcrumbs" >
          <SimpleFormIterator disableReordering >
            <FormDataConsumer >
              {({ getSource }) => {
                console.log('getSource', getSource('link'));
                return <BreadcrumbCreate source={getSource} />;
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
        <ImageInput
          validate={required()}
          source="pictures"
          maxSize={IMAGE_MAX_SIZE}
          options={{
            onDropRejected: (res) => {
              if (res[0].errors[0].code === 'file-too-large') {
                notify('Arquivo excede 15MB', { type: 'warning' });
              }
            },
          }}
          multiple
          label="Fotos do Produto/Serviço (jpeg, png, jpg, gif, svg, webp, bmp)"
          accept="image/*"
        >
          <ImagePreview {...props} />
        </ImageInput>
        <BooleanInput label="Desabilitado ?" source="disabled" />
      </SimpleForm>
    </Edit>
  );
};

const BreadcrumbCreate = ({ source }) => {
  const [cookies] = useCookies([]);
  console.log(source('link'));
  return (
    <div style={{
      display: 'flex',
      width: '100%',
      justifyContent: 'space-around',
      borderBottom: 'none',
    }}>
      <TextInput
        label="Nome"
        fullWidth
        source={source('name')}
        validate={required()}
        style={{
          marginRight: '10px',
        }} />
      {cookies.english === '1' && <TextInput
        label="Nome (Inglês)"
        fullWidth
        source={source('name_english')}
        style={{
          marginRight: '10px',
        }} />}
      {cookies.spanish === '1' && <TextInput
        label="Nome (Espanhol)"
        fullWidth
        source={source('name_spanish')}
        style={{
          marginRight: '10px',
        }} />}
      <TextInput
        label="Link"
        fullWidth
        source={source('href')}
        validate={required()}
        style={{
          marginRight: '10px',
        }} />
    </div>
  );
};

export const BannerInternoCreate = (props) => {
  const permissions = usePermissions();
  const notify = useNotify();
  const onError = (data) => {
    notify(data.message);
  };

  return (
    <Create redirect="list" {...props}>
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        <TextInput
          label="Página"
          fullWidth
          source="page"
          validate={required()}
        />
        <ArrayInput className="breadcrumbsInputArray" source="breadcrumbs" label="Breadcrumbs">
          <SimpleFormIterator disableReordering>
            <FormDataConsumer>
              {({ getSource }) => {
                console.log('getSource', getSource('link'));
                return <BreadcrumbCreate source={getSource} />;
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
        <ImageInput
          validate={required()}
          source="pictures"
          maxSize={IMAGE_MAX_SIZE}
          options={{
            onDropRejected: (res) => {
              if (res[0].errors[0].code === 'file-too-large') {
                notify('Arquivo excede 15MB', { type: 'warning' });
              }
            },
          }}
          multiple
          label="Fotos do Produto/Serviço (jpeg, png, jpg, gif, svg, webp, bmp)"
          accept="image/*"
        >
          <ImagePreview {...props} />
        </ImageInput>
        <BooleanInput label="Desabilitado ?" source="disabled" />
      </SimpleForm>
    </Create>
  );
};

export default BannerInterno;
