import { Editor } from '@tinymce/tinymce-react';
import React, { useRef } from 'react';
import { useInput } from 'react-admin';

export const EditorBlog = (props) => {
  const editorRef = useRef(null);

  const { onChange, onBlur, value, ...rest } = props;
  // field: { value, name, onChange, onBlur, ref}
  const {
      field,
      fieldState: { isTouched, invalid, error },
      formState: { isSubmitted },
      isRequired,
  } = useInput({
      onChange,
      onBlur,
      ...props,
  });

  const handleOnChange = () => {
    if (editorRef.current) {
      const editorValue = editorRef.current.getContent();
      console.log(editorValue);
      field.onChange(editorValue);
    }
  };

  return (
    <>
      {/* <button onClick={log}>show</button> */}
      <Editor
        // apiKey='hdgmfb1jeb9228wecksa5b07neps4yb76rur9a0var9a33la'
        apiKey="c1qk5x0joedqvp9pys2igbdvofm8258y10bklor7xeslzqog"
        onInit={(evt, editor) => editorRef.current = editor}
        initialValue={field.value}
        onBlur={handleOnChange}
        init={{
          height: 500,
          width: 900,
          menubar: '',
          plugins: 'advlist autolink lists link image imagetools table charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount importcss',
          toolbar: 'blocks undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | bullist outdent indent | formatselect | table image media | removeformat code specialcharacter',
          content_style:  'body { font-family:Helvetica,Arial,sans-serif; font-size:16px;} ' +
                          'img[style*="float: left"] {margin-right: 20px;} ' +
                          'img[style*="float: right"]{margin-left: 20px;}  ' +
                          'table[style*="margin-right: auto"]{ float: left; margin-right: 20px;} ' +
                          'table[style*="margin-left: auto"]{ float: right; margin-left: 20px;}',
          content_css: './index.css',
          image_caption: true,
        }}
        {...rest}
      />
    </>
  );
};