import CloseIcon from '@mui/icons-material/Close';
import { format, isValid } from 'date-fns';
import {
  RichTextInput,
  RichTextInputToolbar,
  FormatButtons,
  AlignmentButtons,
  ClearButtons,
  ColorButtons,
  LevelSelect,
  LinkButtons,
  ListButtons,
  QuoteButtons,
} from 'ra-input-rich-text';
import React from 'react';
import {
  List,
  Datagrid,
  DateField,
  EditButton,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  ImageInput,
  ImageField,
  Filter,
  BulkDeleteButton,
  Toolbar,
  SaveButton,
  DeleteButton,
  required,
  TextField,
  BooleanInput,
  usePermissions,
  useRecordContext,
  useNotify,
  ListButton,
  NumberInput,
  minLength,
} from 'react-admin';

import Datas from '../../components/dates';
import { IMAGE_MAX_SIZE } from '../../helpers/constants';
import ToggleBanner from './toggleBanner';

const BannersTitle = () => {
  const record = useRecordContext();
  if (!record) return null;
  const startDate = new Date(
    record.data ? record.data.start_date : record.start_date,
  );
  const endDate = new Date(
    record.data ? record.data.end_date : record.end_date,
  );
  const start = new Date(startDate);
  const end = new Date(endDate);
  const userTimezoneOffset = start.getTimezoneOffset() * 60000;
  start.setTime(start.getTime() + userTimezoneOffset);
  end.setTime(end.getTime() + userTimezoneOffset);

  const texto = isValid(record.end_date)
    ? `até ${format(end, 'd/M/yyyy')}`
    : '';
  return (
    <span>
      Banner {record ? `de ${format(start, 'd/M/yyyy')} ${texto}` : ''}
    </span>
  );
};

const BannersFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar" source="q" alwaysOn />
  </Filter>
);

const PostBulkActionButtons = ({ permissions, ...props }) => (
  <>
    {!permissions.isLoading &&
      (permissions.permissions.includes('SYS_ADMIN') ||
        permissions.permissions.includes('ROLE_BANNERS_DELETE')) && (
        <BulkDeleteButton {...props} />
      )}
  </>
);

export const Banners = (props) => {
  const permissions = usePermissions();

  return (
    <List
      perPage={25}
      title="Banners"
      filters={<BannersFilter />}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
    >
      <Datagrid
        bulkActionButtons={<PostBulkActionButtons permissions={permissions} />}
      >
        <ToggleBanner label="Ativo" source="disabled" {...props} />
        <ImageField
          sortable={false}
          source="image"
          label="Imagem"
          title="Banner"
        />
        <DateField
          options={{ timeZone: 'UTC' }}
          locales="pt-BR"
          source="start_date"
          label="Data Inicial"
        />
        <DateField
          options={{ timeZone: 'UTC' }}
          locales="pt-BR"
          source="end_date"
          label="Data Final"
        />
        <TextField label="Título" source="title" />
        <TextField label="Descrição" source="description" />
        <TextField label="Ordem" source="order" />
        {!permissions.isLoading &&
          (permissions.permissions.includes('SYS_ADMIN') ||
            permissions.permissions.includes('ROLE_BANNERS_UPDATE')) && (
            <EditButton />
          )}
      </Datagrid>
    </List>
  );
};

const CustomToolbar = ({ permissions, ...props }) => (
  <Toolbar
    {...props}
    className="botoesUpdate"
    style={{ justifyContent: 'space-between' }}
  >
    <div
      style={{
        width: '250px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <SaveButton alwaysEnable />
      <ListButton
        size="medium"
        variant="outlined"
        label="cancelar"
        icon={<CloseIcon />}
      />
    </div>
    {!permissions.isLoading &&
      (permissions.permissions.includes('SYS_ADMIN') ||
        permissions.permissions.includes('ROLE_BANNERS_DELETE')) && (
        <DeleteButton size="medium" />
      )}
  </Toolbar>
);

export const BannersEdit = (props) => {
  const permissions = usePermissions();
  const notify = useNotify();

  return (
    <Edit redirect="list" title={<BannersTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        <Datas />
        <TextInput label="Título" fullWidth source="title" />
        <TextInput label="Link" fullWidth source="link" />
        <TextInput
          label="Ordenação"
          source="order"
          type="text"
          validate={[required(), minLength(1)]}
          defaultValue="Ultima ordem"
          parse={(value) => {
            // eslint-disable-next-line
            if (!isNaN(value)) return Number(value) < 1 ? '' : value;
            if (value === 'Ultima ordem' || 'Ultima ordem'.startsWith(value)) return value;
            return '';
          }} />
        <RichTextInput
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <AlignmentButtons />
              <FormatButtons />
              <ColorButtons />
              <ListButtons />
              <LinkButtons />
              <QuoteButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
          label="Descrição"
          source="description"
          fullWidth
        />
        <ImageInput
          validate={required()}
          source="pictures"
          maxSize={IMAGE_MAX_SIZE}
          options={{
            onDropRejected: (res) => {
              if (res[0].errors[0].code === 'file-too-large') {
                notify('Arquivo excede 15MB', { type: 'warning' });
              }
            },
          }}
          label="Imagem (jpeg, png, jpg, gif, svg, webp, bmp)"
          accept="image/*"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <BooleanInput label="Desabilitado ?" source="disabled" />
      </SimpleForm>
    </Edit>
  );
};

export const BannersCreate = (props) => {
  const permissions = usePermissions();
  const agora = new Date();
  agora.setHours(0, 0, 0, 0);
  const userTimezoneOffset = agora.getTimezoneOffset() * 60000;
  agora.setTime(agora.getTime() - userTimezoneOffset);
  const notify = useNotify();
  return (
    <Create redirect="list" {...props}>
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        <Datas timeNow={agora} />
        <TextInput label="Título" fullWidth source="title" />
        <TextInput label="Link" fullWidth source="link" />
        <TextInput
          label="Ordenação"
          source="order"
          type="text"
          validate={[required(), minLength(1)]}
          defaultValue="Ultima ordem"
          parse={(value) => {
            // eslint-disable-next-line
            if (!isNaN(value)) return Number(value) < 1 ? '' : value;
            if (value === 'Ultima ordem') return value;
            return '';
          }} />
        <RichTextInput
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <AlignmentButtons />
              <FormatButtons />
              <ColorButtons />
              <ListButtons />
              <LinkButtons />
              <QuoteButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
          label="Descrição"
          source="description"
          fullWidth
        />
        <ImageInput
          validate={required()}
          source="pictures"
          maxSize={IMAGE_MAX_SIZE}
          options={{
            onDropRejected: (res) => {
              if (res[0].errors[0].code === 'file-too-large') {
                notify('Arquivo excede 15MB', { type: 'warning' });
              }
            },
          }}
          label="Imagem (jpeg, png, jpg, gif, svg, webp, bmp)"
          accept="image/*"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <BooleanInput label="Desabilitado ?" source="disabled" />
      </SimpleForm>
    </Create>
  );
};

export default Banners;
