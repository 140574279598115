import CloseIcon from '@mui/icons-material/Close';
import {
  RichTextInput,
  RichTextInputToolbar,
  FormatButtons,
  AlignmentButtons,
  ClearButtons,
  ColorButtons,
  LevelSelect,
  LinkButtons,
  ListButtons,
  QuoteButtons,
} from 'ra-input-rich-text';
import React from 'react';
import {
  List,
  Datagrid,
  EditButton,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  Filter,
  BulkDeleteButton,
  Toolbar,
  SaveButton,
  DeleteButton,
  required,
  TextField,
  usePermissions,
  useRecordContext,
  CreateButton,
  useListContext,
  TopToolbar,
  ImageInput,
  useNotify,
  ListButton,
} from 'react-admin';

import ImagePreview from '../../components/ImagePreviewWithShowFirst';
import ShortField from '../../components/ShortField';
import { IMAGE_MAX_SIZE } from '../../helpers/constants';

const QuemSomosTitle = () => {
  const record = useRecordContext();
  if (!record) return null;

  return <span>Quem Somos - {record ? `${record.title}` : ''}</span>;
};

const QuemSomosFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Procurar" source="q" alwaysOn />
  </Filter>
);

const PostBulkActionButtons = ({ permissions, ...props }) => (
  <>
    {!permissions.isLoading &&
      (permissions.permissions.includes('SYS_ADMIN') ||
        permissions.permissions.includes('ROLE_QUEM_SOMOS_DELETE')) && (
        <BulkDeleteButton {...props} />
      )}
  </>
);

const ListActions = () => {
  const { total, isLoading } = useListContext();

  return (
    <TopToolbar>{!isLoading && total === 0 && <CreateButton />}</TopToolbar>
  );
};

export const QuemSomos = (props) => {
  const permissions = usePermissions();

  return (
    <List
      perPage={25}
      title="Quem Somos"
      filters={<QuemSomosFilter />}
      sort={{ field: 'id', order: 'DESC' }}
      actions={<ListActions />}
      {...props}
    >
      <Datagrid
        bulkActionButtons={<PostBulkActionButtons permissions={permissions} />}
      >
        <TextField label="Título" source="title" />
        <ShortField source="text" />
        <ShortField label="Idioma" source="language" transform={txt => {
            switch (txt) {
              case 'portuguese':
                return 'Português';
              case 'english':
                return 'Inglês';
              case 'spanish':
                return 'Espanhol';
              default:
                return 'Indefinido';
            }
        }} />
        {!permissions.isLoading &&
          (permissions.permissions.includes('SYS_ADMIN') ||
            permissions.permissions.includes('ROLE_QUEM_SOMOS_UPDATE')) && (
            <EditButton />
        )}
      </Datagrid>
    </List>
  );
};

const CustomToolbar = ({ permissions, ...props }) => (
  <Toolbar
    {...props}
    className="botoesUpdate"
    style={{ justifyContent: 'space-between' }}
  >
    <div
      style={{
        width: '250px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <SaveButton alwaysEnable />
      <ListButton
        size="medium"
        variant="outlined"
        label="cancelar"
        icon={<CloseIcon />}
      />
    </div>
    {!permissions.isLoading &&
      (permissions.permissions.includes('SYS_ADMIN') ||
        permissions.permissions.includes('ROLE_QUEM_SOMOS_DELETE')) && (
        <DeleteButton size="medium" />
      )}
  </Toolbar>
);

export const QuemSomosEdit = (props) => {
  const permissions = usePermissions();
  const notify = useNotify();

  return (
    <Edit redirect="list" title={<QuemSomosTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        <TextInput
          label="Título"
          fullWidth
          source="title"
          validate={required()}
        />
        <RichTextInput
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <AlignmentButtons />
              <FormatButtons />
              <ColorButtons />
              <ListButtons />
              <LinkButtons />
              <QuoteButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
          fullWidth
          label="Texto"
          source="text"
          validate={required()}
        />
        <TextInput label="Link do Youtube" fullWidth source="youtube_link" />
        <ImageInput
          validate={required()}
          source="pictures"
          maxSize={IMAGE_MAX_SIZE}
          options={{
            onDropRejected: (res) => {
              if (res[0].errors[0].code === 'file-too-large') {
                notify('Arquivo excede 15MB', { type: 'warning' });
              }
            },
          }}
          multiple
          label="Fotos do Quem Somos (jpeg, png, jpg, gif, svg, webp, bmp)"
          accept="image/*"
        >
          <ImagePreview {...props} />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};

export const QuemSomosCreate = (props) => {
  const permissions = usePermissions();
  const notify = useNotify();

  return (
    <Create redirect="list" {...props}>
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        <TextInput
          label="Título"
          fullWidth
          source="title"
          validate={required()}
        />
        <RichTextInput
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <AlignmentButtons />
              <FormatButtons />
              <ColorButtons />
              <ListButtons />
              <LinkButtons />
              <QuoteButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
          fullWidth
          label="Texto"
          source="text"
          validate={required()}
        />
        <TextInput label="Link do Youtube" fullWidth source="youtube_link" />
        <ImageInput
          validate={required()}
          source="pictures"
          maxSize={IMAGE_MAX_SIZE}
          options={{
            onDropRejected: (res) => {
              if (res[0].errors[0].code === 'file-too-large') {
                notify('Arquivo excede 15MB', { type: 'warning' });
              }
            },
          }}
          multiple
          label="Fotos do Quem Somos (jpeg, png, jpg, gif, svg, webp, bmp)"
          accept="image/*"
        >
          <ImagePreview {...props} />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export default QuemSomos;
